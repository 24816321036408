.web-logs {
  .content {
    font-size: 14px;
    color: #555;
    padding-top: 4rem;
  }

  .nav-link:nth-child(3),
  .nav-link:nth-child(4) {
    width: 9rem;
  }
}